
import React from 'react'
import ProthamaBuildingelevation from "../images/Final Elevation page.jpg";


function Slider() {
  return (
    <div>

<div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
 
  <div className="carousel-inner">
    <div className="carousel-item active" data-bs-interval="10000">
      <img src={ProthamaBuildingelevation} className="d-block w-100" alt="bestflatrajbarikrishnagar"/>
      <div className="carousel-caption d-none d-md-block">
   
      </div>
    </div>
    <div className="carousel-item" data-bs-interval="2000">
      <img src={ProthamaBuildingelevation} className="d-block w-100" alt="Prothama"/>
      <div className="carousel-caption d-none d-md-block">
      
      </div>
    </div>
    <div className="carousel-item">
      <img src={ProthamaBuildingelevation} className="d-block w-100" alt="Prothama"/>
      <div className="carousel-caption d-none d-md-block">

      </div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>



    </div>
  )
}

export default Slider