import React from 'react'

function Flatbhkprice() {
    return (
        <div>


            <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <div className='btn-grad42 mb-2'><b>1 BHK FLAT AVAILABLE</b></div>
                                <div className="card-body">
                                    <h5 className="card-title">1 BHK FLAT Price</h5>
                                    <p className="card-text">Prcie: <b style={{ color: "darkBlue" }}>15,75,600</b> Onwords (GST Included)* </p>

                                    <a href="tel: 9434054859" className="bhkbutton">Book Now</a>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <div className='btn-grad42 mb-2'><b>2 BHK FLAT AVAILABLE</b></div>
                                <div className="card-body">
                                    <h5 className="card-title">2 BHK FLAT Price</h5>
                                    <p className="card-text">Prcie:<b style={{ color: "darkBlue" }}> 24,60,360 </b>Onwords (GST Included)* </p>

                                    <a href="tel: 9434054859" className="bhkbutton">Book Now</a>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <div className='btn-grad42 mb-2'><b>3 BHK FLAT AVAILABLE</b></div>
                                <div className="card-body">
                                    <h5 className="card-title">3 BHK FLAT Price</h5>
                                    <p className="card-text">Prcie: <b style={{ color: "darkBlue" }}>55,88,835 </b>Onwords (GST Included)* </p>

                                    <a href="tel: 9434054859" className="bhkbutton">Book Now</a>
                                </div>
                            </div>
<p style={{color:"maroon"}}>T&C Apply</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Flatbhkprice
