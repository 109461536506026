import React from 'react'
import Navbar from '../inc/Navbar'
import Amenities from './Amenities'
import ScrollToTop from "react-scroll-to-top";
function Amenitiespage() {
    return (
        <div>

            <Navbar />
            <br />
            <br />
            <br />
           
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="maroon"/>
            <div className='flatkrishnagarnadianew'>
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Amenities</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br/>

            <Amenities />


        </div>
    )
}

export default Amenitiespage