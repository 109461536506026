
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Contactus from './components/pages/Contactus';
import Amenitiespage from './components/pages/Amenitiespage';
import Conectivitypage from './components/pages/Conectivitypage';
import Workpage from './components/pages/Workpage';

function App() {
  return (
    <Router>
    
    <div>

     
      
      <Routes>

      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/Contact-us" element={<Contactus/>}/>
      <Route axact path="/Amenities" element={<Amenitiespage/>}/>
      <Route axact path="/workin-progress" element={<Workpage/>}/>
      <Route axact path="/conectivity" element={<Conectivitypage/>}/>
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
