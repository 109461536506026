import React from 'react'
import Navbar from '../inc/Navbar'
import Workp from './Workp'

function Workpage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br />
        <br />
        <br/>
        
        <div className='flatkrishnagarnadianew'>
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Work In Progress</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br/>
        <Workp/>
        <br/>
    </div>
  )
}

export default Workpage